// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-buggy-friendly-js": () => import("./../../../src/pages/buggy-friendly.js" /* webpackChunkName: "component---src-pages-buggy-friendly-js" */),
  "component---src-pages-filters-js": () => import("./../../../src/pages/filters.js" /* webpackChunkName: "component---src-pages-filters-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wheelchair-friendly-js": () => import("./../../../src/pages/wheelchair-friendly.js" /* webpackChunkName: "component---src-pages-wheelchair-friendly-js" */),
  "component---src-templates-parkrun-details-js": () => import("./../../../src/templates/parkrun-details.js" /* webpackChunkName: "component---src-templates-parkrun-details-js" */)
}

